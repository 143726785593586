import React, { useState } from 'react';
import FrequencyDefectChart from './FrequencyDefectChart';
import { Grid, Typography, Paper, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import theme from "../../../theme";
import FrequencyHelpModal from './FrequencyHelpModal';

export default function FrequencyDefect({ ingredient, allPositionSeriesData }) {

    const [open, setOpen] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    let chartMax = 0;
    allPositionSeriesData.forEach((position) => {
        position.seriesData.forEach((series) => {
            if (series.y > chartMax) {
                chartMax = series.y;
            }
        })
    });
    chartMax += 0.045

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{mb: 1}}
            >
                <Grid item xs={9}>
                    <Typography align="right" variant="h5" style={styles.description}>
                        {ingredient.IngredientDescription}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <IconButton onClick={helpButtonClick} sx={{ float: 'left', ml: 2, color: theme.palette.primary.main }}>
                        <HelpIcon fontSize={'large'} />
                    </IconButton>
                </Grid>
                
            </Grid>
            

            {/* Stack of charts rendered below the description */}
            {allPositionSeriesData.length > 0 ?
                (<Grid
                    container
                    direction="row"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    style={styles.grid}
                >
                    {allPositionSeriesData
                        .filter(positionData => positionData.seriesData && positionData.seriesData.length > 0)
                        .map((positionData, index) => (
                            <Grid item xs={6}>
                                <Item
                                    square={false}
                                    key={index}
                                >
                                    <Typography>{positionData.position}</Typography>
                                    <FrequencyDefectChart
                                        ingredient={ingredient}
                                        positionData={positionData}
                                        max={chartMax}
                                    />
                                </Item>
                            </Grid>

                    ))}
                </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>)}
            <Typography sx={{mt: '1%'}} align="right">Note: A single frequency of maximum amplitude per band is returned.
                A curve is drawn to make interpretation easier.</Typography>
            <FrequencyHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        paddingLeft: '10%'
    },
    grid: {
        justifyContent: 'center',  // Center the charts in the container
        alignItems: 'flex-start',  // Align the charts at the start
    },
};