import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { Card, Stack, Tooltip, Box, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StatusMessage from '../../Generic/StatusMessage';
import StatusBackdrop from "../../Generic/StatusBackdrop";
import AssetDetail from '../AssetDetail';
import {
    fetchDefectDetail,
    fetchVibrationEnums,
    fetchIngredientData,
    processIngredientElevAmpData,
    processIngredientDomAmpData,
    processIngredientFrequencyData,
    processIngredientPhaseData,
    fetchAsset
} from "../VibrationQueryFunctions";
import DefectIngredientModal from './DefectIngredientModal';


const buttonLinkStyle = {
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
};

export default function DefectInformation(props) {
    const [errorState, setErrorState] = useState();
    const [ingredients, setIngredients] = useState([]);
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [seriesData, setSeriesData] = useState([]);
    const [axisOrientationTypes, setAxisOrientationTypes] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const [bands, setBands] = useState([]);
    const [allData, setAllData] = useState([]);
    const [assetName, setAssetName] = useState("");
    
    const [machineType, setMachineType] = useState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const selectedDefect = props.data.item;
    const userID = props.userID;

    // Fetch Vibration Enums (called on ingredient selection)
    useQuery("vibrationEnums", fetchVibrationEnums,
        {
            enabled: !!selectedIngredient, // Only fetch if an ingredient is selected
            onSuccess: (dataE) => {
            setBands(dataE.Bands);
            setAxisOrientationTypes(dataE.AxisOrientations);
            setPositionTypes(dataE.PositionTypes);
            setBands(dataE.Bands);
            },
            onError: (errorE) => {
                setErrorState(errorE || "An error occurred while fetching enums.");
            }
        }
    );

    // Fetch Ingredient Data (called on ingredient selection)
    useQuery(["ingredientData", userID, selectedIngredient?.PrimaryComputedData?.ComputedDataID],
        fetchIngredientData,
        {
            enabled: !!selectedIngredient?.PrimaryComputedData?.ComputedDataID, // Only fetch if an ingredient is selected
            onSuccess: (data) => {
                setAllData(data);
            },
            onError: (error) => {
                setErrorState(error || "An unexpected error occurred.");
            }
        }
    );

    // Get the assets machine type
    useQuery(["fetchAsset", selectedDefect.VibrationObjectID], fetchAsset, {
        onSuccess: (data) => {
            if (data) {
                if (data.VibrationEditAsset != null) {
                    setMachineType(data.VibrationEditAsset.VibrationObject.MachineTypeID);
                    setAssetName(data.VibrationEditAsset.VibrationObject.AssetName);
                }
                else {
                    setMachineType(data.VBTxAssetFormValues.machineType);
                    setAssetName(data.VBTxAssetFormValues.AssetName);
                }
                
            }
        },
        onError: (objectError) => {
            // Handle the error here. For example, you can log the error or set an error state.

            // Optionally, you can set an error state to display an error message to the user.
            setErrorState(objectError || "An unexpected error occurred.");
        },
        enabled: !!selectedDefect.VibrationObjectID
    });

    // Process the data when both enums and ingredient data are available
    useEffect(() => {
        if (selectedIngredient && positionTypes.length > 0 && allData.length > 0 && bands.length > 0) {
            let processedData;
            switch (selectedIngredient.IngredientTypeID)
            {
                case 1:                    
                    processedData = processIngredientElevAmpData(selectedIngredient, allData, positionTypes);
                    setSeriesData(processedData.seriesData);
                    break;
                case 2:
                    processedData = processIngredientDomAmpData(selectedIngredient, allData, positionTypes, bands);  
                    setSeriesData(processedData);
                    break;
                case 3:
                    processedData = processIngredientFrequencyData(selectedIngredient, allData, positionTypes, bands);
                    setSeriesData(processedData);
                    break;
                case 4:
                case 5:
                case 6:
                    processedData = processIngredientPhaseData(selectedIngredient, allData, positionTypes);  
                    setSeriesData(processedData);
                    break;
                default:
                    processedData = null;
                    setSeriesData(processedData);
                    break;
            }
        }
    }, [selectedIngredient, allData, positionTypes, bands]);

    useQuery(["detail", selectedDefect.RecipeStateID], fetchDefectDetail, {
        onSuccess: (dataA) => {
            if (JSON.stringify(dataA) !== JSON.stringify(ingredients)) {
                setIngredients(dataA);
            }
            setIsLoadingDetail(false);
        },
        onError: (errorDefectDetail) => {
            // Handle the error here. For example, you can log the error or set an error state.
            // Optionally, you can set an error state to display an error message to the user.
            setErrorState(errorDefectDetail || "An unexpected error occurred.");
            setIsLoadingDetail(false);
        },
        enabled: !!selectedDefect.RecipeStateID
    });

    const handleIngredientClick = (ingredient) => {
        // Set the selected ingredient to be passed to the modal
        setSelectedIngredient(ingredient);
        setOpen(true); // Open the modal
    };

    const handleIngredientClose = () => {
        setOpen(false);
        setSelectedIngredient(null); // Clear the selected ingredient when the modal is closed
    };

    const ExplanationHTML = () => {
        return (

            <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    border: '1px solid #000',
                    borderRadius: '16px'
                }}>
                    <Tooltip title={`Positive Indicators: ${selectedDefect.PercentComplete}%`} arrow>
                        <Box sx={{
                            height: `${selectedDefect.PercentComplete}%`,
                            backgroundColor: theme.palette.error.main,
                            filter: 'brightness(75%)',
                            width: 10,

                        }} />
                    </Tooltip>
                    <Tooltip title={`Missing Indicators: ${selectedDefect.PercentUnknown}%`} arrow>
                        <Box sx={{
                            height: `${selectedDefect.PercentUnknown}%`,
                            backgroundColor: theme.palette.grey.main,
                            width: 10,
                        }} />
                    </Tooltip>
                    <Tooltip title={`Counter Indicators: ${selectedDefect.PercentBurnt}%`} arrow>
                        <Box sx={{
                            height: `${selectedDefect.PercentBurnt}%`,
                            backgroundColor: alpha(theme.palette.error.main, 0.5),
                            width: 10,
                        }} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', ml: 1 }}>
                    {ingredients
                        .sort((a, b) => {
                            // Sort by PercentComplete descending (records with 100 Complete first)
                            if (a.PercentComplete === 100 && b.PercentComplete !== 100) {
                                return -1;
                            }
                            if (a.PercentComplete !== 100 && b.PercentComplete === 100) {
                                return 1;
                            }

                            // Sort by PercentUnknown descending (records with 100 Unknown next)
                            if (a.PercentUnknown === 100 && b.PercentUnknown !== 100) {
                                return -1;
                            }
                            if (a.PercentUnknown !== 100 && b.PercentUnknown === 100) {
                                return 1;
                            }

                            // Sort by PercentBurnt descending (records with 100 Burnt last)
                            if (a.PercentBurnt === 100 && b.PercentBurnt !== 100) {
                                return -1;
                            }
                            if (a.PercentBurnt !== 100 && b.PercentBurnt === 100) {
                                return 1;
                            }
                            return 0;
                        })
                        .map(ingredient => (
                            <Tooltip title={`ID: ${ingredient.IngredientID} | Name: ${ingredient.IngredientName} | Complete: ${ingredient.PercentComplete} | Missing: ${ingredient.PercentUnknown} | Absent: ${ingredient.PercentBurnt}`} key={ingredient.IngredientID} arrow>
                                <button
                                    onClick={() => handleIngredientClick(ingredient)}
                                    style={buttonLinkStyle}
                                >
                                    <Typography
                                        variant={isMobile ? 'body2' : 'subtitle1'}
                                        component="div"
                                    >
                                        {ingredient.PercentComplete >= 100 && (
                                            <CheckBoxIcon
                                                sx={{ color: theme.palette.error.main, filter: 'brightness(75%)' }}
                                            />
                                        )}
                                        {ingredient.PercentUnknown >= 100 && (
                                            <HelpCenterIcon sx={{ color: theme.palette.grey.main }} />
                                        )}
                                        {ingredient.PercentBurnt >= 100 && (
                                            <DisabledByDefaultIcon
                                                sx={{ color: theme.palette.error.main, opacity: 0.5 }}
                                            />
                                        )}
                                        {ingredient.IngredientDescription}
                                    </Typography>
                                </button>
                            </Tooltip>
                        ))
                    }
                </Box>
            </Box>

        )
    }

    return (
        <React.Fragment>
            {(isLoadingDetail)
                && <StatusBackdrop open={(isLoadingDetail)} />}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    justifyContent: 'space-around',
                    flexDirection: isMobile ? 'column' : 'row',
                }}>
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Defect Information"
                        statusCode={errorState?.request?.status}
                        message={errorState?.message}
                        error={errorState}
                    />
                }
                <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '95%' }}>
                    <Stack direction="column" sx={{ margin: '20px' }}>
                        {!isLoadingDetail && ExplanationHTML()}
                    </Stack>
                </Card>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    justifyContent: 'space-around',
                    flexDirection: isMobile ? 'column' : 'row',
                }}>
                {selectedDefect.VibrationObjectID &&
                    <AssetDetail
                        companyID={selectedDefect.CompanyID}
                        objectID={selectedDefect.VibrationObjectID}
                        userID={props.userID}
                        hideBackToDashboard={true}
                    />
                }
            </Box>
            {open &&
                <DefectIngredientModal
                ingredient={selectedIngredient}
                open={open}
                onClose={handleIngredientClose}
                seriesData={seriesData} // Pass the preprocessed seriesData
                machineType={machineType}
                axisOrientationTypes={axisOrientationTypes}
                assetName={assetName}
                />
            }
        </React.Fragment>
    );
}


