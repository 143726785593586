import React, { useState } from 'react';
import { Typography, Stack, Modal, Button, IconButton, Paper, Grid, Tooltip, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import theme from "../../../theme";
import FrequencyChart from '../images/FrequencyChart.png'

const helpText = [
    {
        title: "What do the Points Mean?",
        text: <Typography align="center">The points on the chart represent the amplitude of a frequency band and the shaft speed
            multiple at which maximum amplitude is occurring. For most defects, knowing the frequency of the alarm levels is most
            important in terms of its relationship to the turning speed of the shaft. A highlighted point (as shown above) displays
            the frequency of interest for this defect.</Typography>
    },
    {
        title: "Why is this Important?",
        text: <Typography align="center">Defects present themselves at specific frequencies based on the turn speed of the motor.
            Determining the relationship between the amplitude peaks and the turning speed of the motor allow us to isolate defects.</Typography>
    },
    {
        title: "What are the Different Frequency Relationships?",
        text: <Stack>
            <Typography align="center" sx={{ mb: 2 }}><strong>Sub-Synchrounous:</strong>  Below Turning Speed of the Shaft. </Typography>
            <Typography align="center" sx={{ mb: 2 }}><strong>Synchrounous:</strong> At Turning Speed of the Shaft or Exact Integer Multiple. </Typography>
            <Typography align="center" sx={{ mb: 2 }}><strong>Non-Harmonic:</strong> Non-Integer Multiple of Shaft Turning Speed. </Typography>
        </Stack>
    },
]

export default function FrequencyHelpModal({ open, handleModalClose }) {
    return (
        <Modal
            open={open}
        >
            <Paper className="modal-paper">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">How do I Interpret Frequency Charts?</Typography>
                    </Grid>
                </Grid>
                <br />
                <Stack alignItems="center">
                    <img
                        src={FrequencyChart}
                        alt={'Frequency Chart'}
                        style={{
                            width: '100%'
                        }}
                    />
                    <br /><br />
                    {helpText.map((block, index) => {
                        return (
                            <Box key={index}>
                                <Typography variant="h5" fontWeight="bold" align="center">{block.title}</Typography>
                                {block.text}
                                <br />
                            </Box>

                        )
                    })}
                </Stack>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Button variant="contained" sx={{ mx: '25%', width: '50%' }} onClick={handleModalClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}