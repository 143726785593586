import React from 'react';
import DefectPeriodicTableRow from './DefectPeriodicTableRow'; // Adjust the import path as necessary

export default function DefectPeriodicTableGrid({ tableElements, recipeID, activeDefects, defaultRecipeStateID, handleElementChange, userID }) {
    return (
        <div>
            {Array.from({ length: 7 }, (_, i) => i + 1).map(rowNumber => (
                <DefectPeriodicTableRow
                    key={rowNumber}
                    tableElements={tableElements}
                    recipeID={recipeID}
                    rowNumber={rowNumber}
                    activeDefects={activeDefects}
                    handleElementChange={handleElementChange}
                    userID={userID}
                />
            ))}
        </div>
    );
}
