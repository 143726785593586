import apiClient from "../../http-common";
import { fetchReques, Opacity } from "../Generic/MiscFunctions";


export const fetchCompanyNodes = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getcompanynodes/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company nodes after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchCompanyNodesVBTx = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getcompanynodesvbtx/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company nodes after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};



export const fetchVibrationEnums = async () => {
    const maxRetries = 1;
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getenums`);
            if (Object.keys(response.data).length === 0) {
                throw new Error("Empty data response."); // Retry on empty data if appropriate
            }
            return response.data; // Return the data if the response is successful
        } catch (error) {
            if (attempt === maxRetries - 1) {
                // Log the error or handle it according to your application's needs
                console.error("Error fetching vibration enums after several attempts:", error);

                // After the final attempt, throw the error
                throw new Error("Failed to fetch vibration enums after several retries.");
            }
            attempt++;
            // Wait for a short delay before trying again, if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    // In case a new error is thrown after retries are exhausted
    throw new Error("An unexpected error occurred while fetching vibration enums.");
};

export const fetchVibrationVBTxEnums = async () => {
    const maxRetries = 1;
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getenumsvbtx`);
            if (Object.keys(response.data).length === 0) {
                throw new Error("Empty data response."); // Retry on empty data if appropriate
            }
            return response.data; // Return the data if the response is successful
        } catch (error) {
            if (attempt === maxRetries - 1) {
                // Log the error or handle it according to your application's needs
                console.error("Error fetching vibration enums after several attempts:", error);

                // After the final attempt, throw the error
                throw new Error("Failed to fetch vibration enums after several retries.");
            }
            attempt++;
            // Wait for a short delay before trying again, if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    // In case a new error is thrown after retries are exhausted
    throw new Error("An unexpected error occurred while fetching vibration enums.");
};



export const fetchVibrationDashboard = async ({ queryKey }) => {
    const [_key, companyID, userID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID || !userID) {
        throw new Error("Both 'companyID' and 'userID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getvibdashboard/${companyID}/${userID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch vibration dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchUserInfo = async ({ queryKey }) => {
    const [_key, companyID, viewAll] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/User/getalluserinfo/${companyID}/${viewAll}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch user info after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchAsset = async ({ queryKey }) => {
    const [_key, objectID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!objectID) {
        throw new Error("The 'objectID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getobject/${objectID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch asset data after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchCompanyVibrationAlarms = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getvibalarms/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company vibration alarms after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchAssetDetail = async ({ queryKey }) => {
    const [_key, objectID, userID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID || !objectID) {
        throw new Error("Both 'userID' and 'objectID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getassetdetail/${objectID}/${userID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch asset detail after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const postAsset = async (data) => {
    try {
        const response = await apiClient.post(`/Vibration/postAsset/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error posting asset data:", error);
        throw new Error("Error posting asset data:");
    }
};

export const postAssetVBTx = async (data) => {
    try {
        const response = await apiClient.post(`/Vibration/postAssetVBTx/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error posting asset data:", error);
        throw new Error("Error posting asset data:");
    }
};


export const updateAsset = async (data) => {
    try {
        const response = await apiClient.put(`/Vibration/putAsset/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating asset:", error);
        // Handle the error as needed (e.g., re-throw, return error message, etc.)
        throw new Error("Error updating asset:"); // or return a specific error response
    }
};

export const updateAssetVBTx = async (data) => {
    try {
        const response = await apiClient.put(`/Vibration/putAssetvbtx/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating asset:", error);
        // Handle the error as needed (e.g., re-throw, return error message, etc.)
        throw new Error("Error updating asset:"); // or return a specific error response
    }
};


export const deleteAsset = async (data) => {
    //console.log("DATA: " + JSON.stringify(data));
    try {
        const response = await apiClient.delete(`/Vibration/deleteAsset`, { data });
        //console.log(JSON.stringify(response));
        return response;
    } catch (error) {
        console.error("Error deleting asset:", error);
        // Handle the error as needed
        throw error("Error deleting asset:"); // or return a specific error response
    }
};

export const fetchDefectDashboard = async ({ queryKey }) => {
    const [_key, userID, companyID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/masterdefectlist/${userID}?companyID=${companyID}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

export const fetchDefectDetail = async ({ queryKey }) => {
    const [_key, recipeStateID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!recipeStateID) {
        throw new Error("The 'recipeStateID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/defectdetail/${recipeStateID}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect detail after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

export const fetchDefectRecipes = async ({ queryKey }) => {
    const [_key, userID, vibrationObjectID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }
    const vibObjectIDIsPresent =
        vibrationObjectID !== undefined
        && vibrationObjectID !== null
        && vibrationObjectID !== 'null';


    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/masterdefectlist/${userID}${vibObjectIDIsPresent ? '?vibrationObjectID=' + vibrationObjectID : ''}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

export const fetchIngredientData = async ({ queryKey }) => {
    const [_key, userID, computedDataID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!computedDataID) {
        throw new Error("The 'computedDataID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/ingredientdata/${userID}/${computedDataID}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch ingredient data after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

const CHART_COLUMNS = [
    {
        position: 0.5,
        category: 0.5
    },
    {
        position: 1.5,
        category: 1.5
    },
    {
        position: 4,
        category: 4
    },
    {
        position: 5.5,
        category: 5.5
    },]

export const processIngredientElevAmpData = (ingredient, allData, positionTypes) => {
    // Filter data into positions based on PositionID
    const position1Data = allData.filter(item => item.PositionID === 1);
    const position2Data = allData.filter(item => item.PositionID === 2);
    const position3Data = allData.filter(item => item.PositionID === 3);
    const position4Data = allData.filter(item => item.PositionID === 4);

    // Combine all filtered positions
    const combinedFilteredPositions = [
        ...position1Data,
        ...position2Data,
        ...position3Data,
        ...position4Data
    ];
    // Filter and create series data ensuring all necessary fields are numbers
    const seriesData = combinedFilteredPositions
        .filter(position =>
            position !== null &&
            position.AxisID === ingredient.PrimaryComputedData.AxisID &&
            position.BandID === ingredient.PrimaryComputedData.BandID &&
            position.UnitTypeSymbol === ingredient.PrimaryComputedData.UnitTypeSymbol
        ) // Filter out positions that do not match ingredient's AxisID, BandID, and UnitTypeSymbol
        .map(position => {
            let color = '#5CB85C';
            if (position.ComputedValue > position.WarningAlarmLevel) {
                color = '#F0AD4E';
            }
            if (position.ComputedValue > position.DangerAlarmLevel) {
                color = '#B30101';
            }
            if (ingredient.PrimaryComputedData.ComputedDataSourceID !== position.ComputedDataSourceID) {
                color = Opacity(color, 0.3);
            }
            // Find the position type by matching PositionID
            const positionType = positionTypes.find(pt => pt.PositionID === position.PositionID);
            if (positionType) {
                const positionName = `P${position.PositionID} - ${positionType.DisplayName}`;
                return {
                    x: Number(positionType.PositionID), // Ensure x is a number
                    name: positionName + "<br>" + position.NodeDisplayName.split(' ')[1], // Display name of the position
                    y: Number(position.ComputedValue), // Ensure y is a number
                    dashboardDataID: Number(position.DashboardDataID), // Ensure dashboardDataID is a number
                    color: color, // Color based on matching data source
                    channelDisplayName: position
                        ? (ingredient.PrimaryComputedData.ComputedDataSourceID === position.ComputedDataSourceID
                            ? `ELEVATED ${position.ChannelDisplayName ?? ''}`
                            : position.ChannelDisplayName ?? '')
                        : '',
                    warningAlarmLevel: position.WarningAlarmLevel,
                    dangerAlarmLevel: position.DangerAlarmLevel,
                    chartColumn: CHART_COLUMNS[positionType.PositionID - 1]
                };
            }
            return null;
        })
        .filter(position => position !== null); // Remove null values if positionType is not found
    return { seriesData };
};

export const processIngredientDomAmpData = (ingredient, allData, positionTypes, bands) => {
    // Filter the data to include only items that match the AxisID of the ingredient's PrimaryComputedData
    const filteredData = allData.filter(item =>
        item.AxisID === ingredient.PrimaryComputedData.AxisID
    );

    // Create series data per position
    const seriesDataByPosition = positionTypes.map(positionType => {
        // Filter data based on PositionID
        const positionData = filteredData.filter(item => item.PositionID === positionType.PositionID);

        // Create series data by mapping over the bands
        const seriesData = bands.filter(item => item.ID != 1).map(band => {
            // Find data for the current position and band
            
            const bandData = positionData.find(item => item.BandID === band.ID);

            let color = '#5CB85C';
            if (bandData) {
                if (bandData.ComputedValue > bandData.WarningAlarmLevel) {
                    color = '#F0AD4E';
                }
                if (bandData.ComputedValue > bandData.DangerAlarmLevel) {
                    color = '#B30101';
                }
                if (ingredient.PrimaryComputedData.ComputedDataSourceID !== bandData.ComputedDataSourceID) {
                    color = Opacity(color, 0.3);
                }
            }
            
            return {
                x: band.DisplayAbbreviation, // Use Band.DisplayAbbreviation as the x-axis label
                y: bandData ? bandData.ComputedValue : null, // ComputedValue or null if no data found for this band
                dashboardDataID: bandData ? bandData.DashboardDataID : null, // Include dashboardDataID if present
                color: color,
                channelDisplayName: bandData
                    ? (bandData.ComputedDataSourceID === ingredient.PrimaryComputedData.ComputedDataSourceID
                        ? `DOMINANT ${bandData.ChannelDisplayName}`
                        : bandData.ChannelDisplayName)
                    : '',
                warningAlarmLevel: bandData ? bandData.WarningAlarmLevel : null,
                dangerAlarmLevel: bandData ? bandData.DangerAlarmLevel : null,
            };
            
        }).filter(point => point.y !== null); // Remove entries where no ComputedValue was found
        return {
            position: `P${positionType.PositionID} - ${positionType.DisplayName} ${positionData[0] ? positionData[0].NodeDisplayName.split(' ')[1] : 0}`, // Display name of the position
            seriesData: seriesData
        };
    });
    return seriesDataByPosition.filter(data => data.seriesData.length > 0);
};

export const processIngredientFrequencyData = (ingredient, allData, positionTypes, bands) => {
    // Filter the data to include only items that match the AxisID of the ingredient's PrimaryComputedData
    const filteredData = allData.filter(item =>
        item.AxisID === ingredient.PrimaryComputedData.AxisID
    );
    // Create series data per position
    const seriesDataByPosition = positionTypes.map(positionType => {
        // Filter data based on PositionID
        const positionData = filteredData.filter(item => item.PositionID === positionType.PositionID);

        let highligthedMultiple = -1;

        // Create series data by mapping over the bands
        const seriesData = bands.map(band => {

            // Find data for the current position and band
            const bandData = positionData.filter(item => item.BandID === band.ID);
            //If there is no data for this band skip it
            if (bandData.length < 2) {
                return null;
            }

            if (band.MaxOrder == null) {
                return null;
            }
            //Get the Amplitude and Frequency data TODO: Possibly find better way to determine 
            //which is which besides position
            const ampData = bandData[0];
            const freqData = bandData[1];
            let computedOrder = Math.round(((freqData.ComputedValue * 60) / ingredient.SecondaryComputedData.ComputedValue * 10)) / 10 //What sort of rounding is acceptable here?

            //If it is the highlighted order, then we should highlight that multiple on the graph
            if (freqData.ComputedDataSourceID === ingredient.PrimaryComputedData.ComputedDataSourceID) {
                highligthedMultiple = computedOrder;
            }
            return {
                x: freqData ? computedOrder : null, // Use Frequency order as x axis value
                y: ampData ? ampData.ComputedValue : null, // ComputedValue or null if no data found for this band
                dashboardDataID: ampData ? ampData.DashboardDataID : null, // Include dashboardDataID if present
                color: freqData && freqData.ComputedDataSourceID === ingredient.PrimaryComputedData.ComputedDataSourceID ? '#B30101' : '#000000', // Color based on matching ComputedDataSourceID
                channelDisplayName: freqData
                    ? (freqData.ComputedDataSourceID === ingredient.PrimaryComputedData.ComputedDataSourceID
                        ? `SYNCHRONOUS - ${freqData.ChannelDisplayName}`
                        : freqData.ChannelDisplayName)
                    : '',
            };

        }).filter(point => point && point.y !== null && point.x !== null); // Remove entries where no ComputedValue was found
        return {
            position: `P${positionType.PositionID} - ${positionType.DisplayName}`, // Display name of the position
            highlightedMultiple: highligthedMultiple,
            seriesData: seriesData
        };
    });
    return seriesDataByPosition;

}

export const processIngredientPhaseData = (ingredient, allData, positionTypes) => {

    // Create series data per position
    const phaseAnglesByPosition = positionTypes.map(positionType => {
        // Filter data based on PositionID
        const positionData = allData.filter(item => item.PositionID === positionType.PositionID);

        //Get all phase angles, which have no Band ID
        const phaseAngles = positionData.filter(item => item.BandID === null);

        return {
            position: `P${positionType.PositionID} - ${positionType.DisplayName}`, // Display name of the position
            positionID: positionType.PositionID,
            //Get all of the phase angles from the data
            phaseAngles: phaseAngles.map((phaseAngle) => {
                const isHighlighted = (phaseAngle.ComputedDataSourceID === ingredient.PrimaryComputedData.ComputedDataSourceID || phaseAngle.ComputedDataSourceID === ingredient.SecondaryComputedData.ComputedDataSourceID)

                return {

                    angle: phaseAngle ? Math.round(phaseAngle.ComputedValue) : null,
                    axisID: phaseAngle ? phaseAngle.AxisID : null,
                    orientationID: phaseAngle ? phaseAngle.OrientationID : null,
                    dashboardDataID: phaseAngle ? phaseAngle.DashboardDataID : null, // Include dashboardDataID if present
                    isHighlighted: isHighlighted,
                    color: phaseAngle && isHighlighted ? '#F0AD4E' : '#bfbfbf', // Color based on matching ComputedDataSourceID
                    channelDisplayName: phaseAngle
                        ? phaseAngle.ChannelDisplayName
                        : '',
                };
            })
        }
    });
    return phaseAnglesByPosition;
}
