import React from 'react';
import GenericMachineTypeBorderless from '../images/GenericMachineTypeBorderless.jpg';

export default function BigAssetImage({style }) {

    return (
        <img
            src={GenericMachineTypeBorderless}
            alt={'GenericMachineType'}
            style={style}>
        </img>); 
}